import Schema from 'validate';
import * as iban from 'iban';
import * as googelib from 'google-libphonenumber';
import { ApplicationForm } from '../types';

const isBrowser =
  typeof window !== 'undefined' &&
  {}.toString.call(window) === '[object Window]';

const emailRegEx: RegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const vendorID: RegExp = /^[0-9]{7}\-[0-9]{1}$/;

const postalCode: RegExp = /[0-9]{5}/;

const isValidIban = iban.isValid;

const isValidPhone = (phoneVal: string) => {
  try {
    const instance = googelib.PhoneNumberUtil.getInstance();

    const phone = instance.parse(phoneVal, 'FI');

    return instance.isValidNumber(phone);
  } catch (err) {
    return false;
  }
};

const applicationSchema = new Schema({
  firstName: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    message: {
      required: `Lisää etunimi!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  lastName: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    message: {
      required: `Lisää sukunimi!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  streetAddress: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    message: {
      required: `Lisää osoite!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  email: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    match: emailRegEx,
    message: {
      match: `Sähköposti ei ole validi, anna validi sähköposti.`,
      required: `Lisää sähköposti!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  phone: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    use: { isValidPhone },
    message: {
      required: `Lisää puhelinnumero`,
      isValidPhone: `Puhelinnumero ei ole validi, anna validi puhelinnumero.`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  IBAN: {
    type: String,
    required: true,
    use: { isValidIban },
    length: { min: 3, max: 32 },
    message: {
      required: `Lisää tilinumero!`,
      isValidIban: `IBAN ei ole validi, anna validi IBAN`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  networkCompany: {
    type: String,
    required: true,
    length: { min: 3, max: 40 },
    message: {
      required: `Lisää verkkoyhtiö!`,
      length: `Pituus vähintään 3 ja enintään 40 merkkiä.`
    }
  },
  panelVendorName: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    message: {
      required: `Lisää paneelien toimittaja!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  panelVendorId: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    match: vendorID,
    message: {
      match: `Y-tunnus ei ole validi, anna validi y-tunnus.`,
      required: `Lisää toimittajan y-tunnus!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  permanentUse: {
    required: true,
    type: isBrowser ? 'boolean' : 'string',
    enum: isBrowser ? [true] : ['true'],
    message: {
      enum: `Valintalaatikon tulee olla täydennetty jatkaaksesi!`
    }
  },
  subscriptionNumber: {
    required: false,
    type: String,
    length: { min: 0, max: 32 },
    message: {
      length: `Pituus vähintään 4 ja enintään 32 merkkiä`
    }
  },
  additionalNotes: {
    type: String,
    required: true,
    length: { min: 30, max: 1100 },
    message: {
      required: `Lisää kohteen kuvaus!`,
      length: `Pituus vähintään 30 ja enintään 1100 merkkiä.`
    }
  },
  homeTown: {
    type: String,
    required: true,
    length: { min: 2, max: 32 },
    message: {
      required: `Lisää kotikunta!`,
      length: `Pituus vähintään 2 ja enintään 32 merkkiä.`
    }
  },
  postOffice: {
    type: String,
    required: true,
    // length: { min: isBrowser ? 2 : 7, max: isBrowser ? 32 : 32 * 2 + 5 },
    length: { min: 2, max: 32 },
    message: {
      required: `Lisää postitoimipaikka!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä.`
    }
  },
  postalCode: {
    type: String,
    required: true,
    length: { min: 5, max: 5 },
    match: postalCode,
    message: {
      match: `Postinumero ei ole validi, anna validi postinumero.`,
      required: `Lisää postinumero!`,
      length: `Pituus 5 numeroa.`
    }
  },
  panelElectricNumber: {
    type: String,
    required: true,
    length: { min: 2, max: 32 },
    message: {
      required: `Lisää käyttöpaikan numero!`,
      length: `Pituus vähintään 2 ja enintään 32 merkkiä.`
    }
  },
  attachmentByMail: {
    required: false,
    type: isBrowser ? 'boolean' : 'string'
  },
  agreementCheckbox: {
    required: true,
    type: isBrowser ? 'boolean' : 'string',
    enum: isBrowser ? [true] : ['true'],
    message: {
      enum: `Valintalaatikon tulee olla täydennetty jatkaaksesi!`
    }
  }
});

const trackingLoginSchema = new Schema({
  email: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    match: emailRegEx,
    message: {
      match: `Sähköposti ei ole validi, anna validi sähköposti`,
      required: `Lisää sähköposti!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä`
    }
  },
  trackingId: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    message: {
      required: `Lisää Hakemuksen numero!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä`
    }
  }
});

const userLoginSchema = new Schema({
  email: {
    type: String,
    required: true,
    length: { min: 3, max: 32 },
    match: emailRegEx,
    message: {
      match: `Sähköposti ei ole validi, anna validi sähköposti`,
      required: `Lisää sähköposti!`,
      length: `Pituus vähintään 3 ja enintään 32 merkkiä`
    }
  },
  password: {
    type: String,
    required: true,
    message: {
      required: `Lisää salasana!`
    }
  }
});

export function validateApplication(application: ApplicationForm) {
  const errors = applicationSchema.validate(application);
  return errors;
}

export function validateTrackingLoginInfo(loginInfo: any) {
  const errors = trackingLoginSchema.validate(loginInfo);
  return errors;
}

export function validateUserLoginInfo(loginInfo: any) {
  const errors = userLoginSchema.validate(loginInfo);
  return errors;
}
