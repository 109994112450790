import * as React from 'react';
import Button from '@material-ui/core/Button';
import AttachmentList from './AttachmentList';

interface FileUploaderProps {
  files: object | null;
  handleFileUpload: (files: FileList | null) => void;
  removeAttachment: (index: string) => void;
}

class FileUploader extends React.Component<FileUploaderProps> {
  public fileInputRef: React.RefObject<HTMLInputElement>;

  public constructor(props: FileUploaderProps) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  public resetFileInput = () => {
    this.fileInputRef.current.value = '';
  };

  public render() {
    return (
      <div className="attachment-div exo-font">
        <input
          ref={this.fileInputRef}
          accept="image/*, application/pdf"
          className="hidden-input"
          id="outlined-button-file"
          multiple={true}
          type="file"
          onChange={e => {
            this.props.handleFileUpload(e.target.files);
          }}
        />
        <label htmlFor="outlined-button-file">
          <Button
            variant="outlined"
            component="span"
            className="btn exo-font AppButton AppButtonColor">
            ETSI <i className="material-icons search-icon">search</i>
          </Button>
        </label>
        {this.props.files ? (
          <AttachmentList
            files={this.props.files}
            removeAttachment={this.props.removeAttachment}
            resetFileInput={this.resetFileInput}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default FileUploader;
