import * as React from 'react';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import '../../../../src/index.css';
export interface AttachmentProps {
  index: string;
  fileName: string;
  fileOriginalName?: string;
  fileUrl?: string;
  removeAttachment?: (index: string) => void;
  resetFileInput?: () => void;
}

const Attachment: React.FunctionComponent<AttachmentProps> = props => (
  <div className="attachment-container exo-font">
    <>
      <span>
        {props.fileUrl != null ? (
          <a className="attachment-link" href={props.fileUrl}>
            <em>{props.fileOriginalName}</em>
          </a>
        ) : (
          <em>{props.fileName}</em>
        )}
      </span>
      {!props.removeAttachment ? (
        <></>
      ) : (
        <IconButton
          className="clear-attachment clearButton"
          aria-label="Delete"
          hidden={true}
          onClick={e => {
            props.removeAttachment(props.index);
            props.resetFileInput();
          }}>
          {props.fileUrl == null ? (
            <>
              <ClearIcon className="clearButton-icon clearButton-icon" />
            </>
          ) : (
            ''
          )}
        </IconButton>
      )}
    </>
  </div>
);

export default Attachment;
