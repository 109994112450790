import * as React from 'react';
import './applicationItem.css';
import {
  ApplicationForm,
  ApplicationStatus
} from '../../../../sses-backend/types';
import { Timestamp } from '@google-cloud/firestore';
import { checkApplicationStatus } from '../../utils/sortingUtils';
import Swal from 'sweetalert2';
import { db } from '../../firebaseApp';

interface ApplicationItemProps {
  index: string;
  application: ApplicationForm;
  handleItemClick: (event: React.MouseEvent<Element>) => void;
}

class ApplicationItem extends React.Component<ApplicationItemProps> {
  public formatDate = (timestamp: Timestamp) => {
    const date = timestamp.toDate();
    const formattedDate =
      date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    return formattedDate;
  };

  public updateStatusInFirestore = async (
    applicationEventValue: ApplicationStatus
  ) => {
    const collectionRef = db.collection('application_forms');
    const querySnapshot = await collectionRef
      .where('trackingId', '==', this.props.application.trackingId)
      .get();
    const applicationDocument = querySnapshot.docs[0];

    collectionRef
      .doc(applicationDocument.id)
      .update({
        status: applicationEventValue
      })
      .catch(error => {
        Swal.fire({
          title: 'Virhe',
          html:
            'Virhe päivitettäessä tietokantaa <br/> yritä myöhemmin uudelleen.',
          type: 'error'
        });
      });
  };

  public handleStatusChangeSelect = async (event: React.ChangeEvent<any>) => {
    const applicationEventValue: ApplicationStatus = +event.target.value;

    Swal.fire({
      title: 'Hakemuksen tilan vaihto',
      html: `Muokkaat hakemuksen ${
        this.props.application.trackingId
      } tilaa. </br></br>
      Vanha tila: ${checkApplicationStatus(
        this.props.application.status
      )} </br></br>
      Valittu tila: ${checkApplicationStatus(applicationEventValue)}`,
      type: 'info',
      showCancelButton: true,
      confirmButtonText: 'Hyväksy',
      cancelButtonText: 'Peruuta'
    }).then(result => {
      if (result.value) {
        this.updateStatusInFirestore(applicationEventValue);
      }
    });
  };

  public render() {
    return (
      <tr
        className="application-item"
        id={this.props.application.trackingId}
        onClick={this.props.handleItemClick}>
        <td>
          {this.props.application.applicantNumberInQueue != null &&
          this.props.application.applicantNumberInQueue >= 0
            ? this.props.application.applicantNumberInQueue + 1 + '.'
            : '--'}
        </td>
        <td>
          {this.props.application.firstName +
            ' ' +
            this.props.application.lastName}
        </td>
        <td>{this.props.application.trackingId}</td>
        <td>
          {this.props.application.submissionDate
            ? this.formatDate(this.props.application
                .submissionDate as Timestamp)
            : ''}
        </td>
        {/* <td>{checkApplicationStatus(props.application.status)}</td> */}
        <td>
          <select
            onChange={this.handleStatusChangeSelect}
            onClick={e => e.stopPropagation()}
            value={this.props.application.status}
            className="form-control">
            <option disabled={true} value={ApplicationStatus.UNSIGNED}>
              ODOTTAA ALLEKIRJOITUSTA
            </option>
            <option
              disabled={
                this.props.application.status === ApplicationStatus.UNSIGNED
              }
              value={ApplicationStatus.SIGNED}>
              ODOTTAA KÄSITTELYÄ
            </option>
            <option
              disabled={
                this.props.application.status === ApplicationStatus.UNSIGNED
              }
              value={ApplicationStatus.APPROVED}>
              ODOTTAA VERKKOYHTIÖTÄ
            </option>
            <option
              disabled={
                this.props.application.status === ApplicationStatus.UNSIGNED
              }
              value={ApplicationStatus.REJECTED}>
              HYLÄTTY
            </option>
            <option
              disabled={
                this.props.application.status === ApplicationStatus.UNSIGNED
              }
              value={ApplicationStatus.FINALIZED}>
              MAKSATUKSESSA
            </option>
            <option
              disabled={
                this.props.application.status === ApplicationStatus.UNSIGNED
              }
              value={ApplicationStatus.PAID}>
              MAKSETTU
            </option>
          </select>
        </td>
      </tr>
    );
  }
}

export default ApplicationItem;
