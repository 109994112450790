import * as React from 'react';
import {
  ApplicationForm,
  ApplicationStatus
} from '../../../sses-backend/types';
import { OrderByDirection, Timestamp } from '@google-cloud/firestore';
import * as moment from 'moment';

/**
 * Function that accepts an array of application forms
 * and an application status as parameters and returns an array of
 * applications with the specified status.
 *
 * @param applications Array of ApplicationForm
 * @param status ApplicationStatus to filter by
 *
 * @returns Array of ApplicationForm with the specified status.
 *
 * @author Bogdan Moroz
 */
export const filterByStatus = (
  applications: ApplicationForm[],
  status: ApplicationStatus
) => {
  if (status === ApplicationStatus.ALL) {
    return applications;
  }
  return applications.filter(application => application.status === status);
};

/**
 * Function that accepts an array of application forms
 * and a startDate as parameters and returns an array of
 * applications with submissionDate after the specified
 * startDate.
 *
 * @param applications Array of ApplicationForm
 * @param startDate Start date to filter by
 *
 * @returns Array of ApplicationForm submitted
 * after startDate
 *
 * @author Bogdan Moroz
 */
export const filterByStartDate = (
  applications: ApplicationForm[],
  startDate: Date
) => {
  return applications.filter(application => {
    if (application.submissionDate) {
      return moment((application.submissionDate as Timestamp).toDate()).isAfter(
        startDate
      );
    }
    return false;
  });
};

/**
 * Function that accepts an array of application forms
 * and an endDate as parameters and returns an array of
 * applications with submissionDate before the specified
 * endDate.
 *
 * @param applications Array of ApplicationForm
 * @param endDate End date to filter by
 *
 * @returns Array of ApplicationForm submitted before
 * endDate
 *
 * @author Bogdan Moroz
 */
export const filterByEndDate = (
  applications: ApplicationForm[],
  endDate: Date
) => {
  return applications.filter(application => {
    if (application.submissionDate) {
      return moment((application.submissionDate as Timestamp).toDate())
        .set('hours', 0)
        .isBefore(endDate);
    }
    return false;
  });
};

/**
 * Free search on all the string fields of every object in array.
 * Function that accepts an array of ApplicationForm and
 * a string and then conducts a search on all of the string
 * properties of every ApplicationForm in the array and
 * returns an array of ApplicationForm that match.
 *
 * @param applications Array of ApplicationForm
 * @param searchString A string to search for
 *
 * @returns Array of ApplicationForm with any field that includes
 * the search string
 *
 * @author Bogdan Moroz
 */
export const filterByString = (
  applications: ApplicationForm[],
  searchString: string
) => {
  return applications.filter(application => {
    const keys = Object.keys(application);
    for (const field of keys) {
      if (typeof application[field] === 'string') {
        if (
          application[field].toUpperCase().includes(searchString.toUpperCase())
        ) {
          return true;
        }
      }
    }
    return false;
  });
};

/**
 * Function that accepts an array of ApplicationForm,
 * a fieldname and an order (ascending/descending),
 * and sorts applications by that field in that order
 *
 * @param applications Array of ApplicationForm to sort
 * @param fieldName Field name to sort by
 * @param order Ascending or descending order
 *
 * @returns Array of ApplicationForm sorted in specified order
 *
 * @author Bogdan Moroz
 */
export const sortApplications = (
  applications: ApplicationForm[],
  fieldName: string,
  order: OrderByDirection
): ApplicationForm[] => {
  if (fieldName === 'applicantNumberInQueue') {
    // Only signed applications have an applicantNumberInQueue
    // Hence the if blocks
    if (order === 'asc') {
      applications.sort((a, b) => {
        const numberA = a.applicantNumberInQueue;
        const numberB = b.applicantNumberInQueue;

        if (numberA == null && numberB == null) {
          return 0;
        }

        if (numberA == null && numberB != null) {
          return 1;
        }

        if (numberA != null && numberB == null) {
          return -1;
        }

        if (numberA != null && numberB != null) {
          return numberA - numberB;
        }
      });
    } else {
      applications.sort((a, b) => {
        const numberA = a.applicantNumberInQueue;
        const numberB = b.applicantNumberInQueue;

        if (numberA == null && numberB == null) {
          return 0;
        }

        if (numberA == null && numberB != null) {
          return 1;
        }

        if (numberA != null && numberB == null) {
          return -1;
        }

        if (numberA != null && numberB != null) {
          return numberB - numberA;
        }
      });
    }
  } else if (fieldName === 'lastName') {
    if (order === 'asc') {
      applications.sort((a, b) => {
        const lastNameA = a.lastName.toUpperCase();
        const lastNameB = b.lastName.toUpperCase();
        return lastNameA < lastNameB ? -1 : lastNameA > lastNameB ? 1 : 0;
      });
    } else {
      applications.sort((a, b) => {
        const lastNameA = a.lastName.toUpperCase();
        const lastNameB = b.lastName.toUpperCase();
        return lastNameA > lastNameB ? -1 : lastNameA < lastNameB ? 1 : 0;
      });
    }
  } else if (fieldName === 'trackingId') {
    if (order === 'asc') {
      applications.sort((a, b) => {
        const tackingIdA = +a.trackingId;
        const trackingIdB = +b.trackingId;
        return tackingIdA < trackingIdB ? -1 : tackingIdA > trackingIdB ? 1 : 0;
      });
    } else {
      applications.sort((a, b) => {
        const tackingIdA = +a.trackingId;
        const trackingIdB = +b.trackingId;
        return tackingIdA > trackingIdB ? -1 : tackingIdA < trackingIdB ? 1 : 0;
      });
    }
  } else if (fieldName === 'submissionDate') {
    if (order === 'asc') {
      applications.sort((a, b) => {
        const dateA = a.submissionDate;
        const dateB = b.submissionDate;
        return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
      });
    } else {
      applications.sort((a, b) => {
        const dateA = a.submissionDate;
        const dateB = b.submissionDate;
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      });
    }
  } else if (fieldName === 'status') {
    if (order === 'asc') {
      applications.sort((a, b) => {
        const statusA = a.status;
        const statusB = b.status;
        return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
      });
    } else {
      applications.sort((a, b) => {
        const statusA = a.status;
        const statusB = b.status;
        return statusA > statusB ? -1 : statusA < statusB ? 1 : 0;
      });
    }
  }
  return applications;
};

// TODO move the following functions to a different class. This one is supposed to be for sortingUtils only
export const checkApplicationStatus = (applicationState: ApplicationStatus) => {
  let applicationStatusFin: string;

  switch (applicationState) {
    case ApplicationStatus.UNSIGNED:
      applicationStatusFin = 'ODOTTAA ALLEKIRJOITUSTA';
      break;

    case ApplicationStatus.SIGNED:
      applicationStatusFin = 'ODOTTAA KÄSITTELYÄ';
      break;

    case ApplicationStatus.APPROVED:
      applicationStatusFin = 'ODOTTAA VERKKOYHTIÖTÄ';
      break;

    case ApplicationStatus.REJECTED:
      applicationStatusFin = 'HYLÄTTY';
      break;

    case ApplicationStatus.FINALIZED:
      applicationStatusFin = 'MAKSATUKSESSA';
      break;

    case ApplicationStatus.PAID:
      applicationStatusFin = 'MAKSETTU';
      break;

    default:
      applicationStatusFin = 'VIRHE STATUKSESSA';
      break;
  }
  return applicationStatusFin;
};

export const checkApplicationStatusStyle = (
  applicationState: ApplicationStatus
) => {
  let applicationStatusStyle: string;

  switch (applicationState) {
    case ApplicationStatus.UNSIGNED:
      applicationStatusStyle = 'text-muted';
      break;

    case ApplicationStatus.SIGNED:
      applicationStatusStyle = 'text-dark';
      break;

    case ApplicationStatus.APPROVED:
      applicationStatusStyle = 'text-success';
      break;

    case ApplicationStatus.REJECTED:
      applicationStatusStyle = 'text-danger';
      break;

    case ApplicationStatus.FINALIZED:
      applicationStatusStyle = 'text-primary';
      break;
    default:
      applicationStatusStyle = 'text-dark';
      break;
  }
  return applicationStatusStyle;
};

// interface StatusMessageProps {
//   message: string;
// }

export const resolveApplicationStatusMessage = (
  applicationState: ApplicationStatus
) => {
  let applicationStatusMessage: string;
  switch (applicationState) {
    case ApplicationStatus.UNSIGNED:
      applicationStatusMessage =
        'Kiitos hakemuksesta! Ole hyvä ja allekirjoita hakemus painamalla “allekirjoita”-painiketta. Allekirjoittamiseen tarvitset pankkitunnukset tai mobiilivarmenteen. Saat linkin allekirjoituspalveluun myös sähköpostiisi. Hakemus raukeaa, mikäli hakemusta ei allekirjoiteta viiden päivän sisällä. Huomaa, että allekirjoituksen jälkeen voi kestää n. minuutin, ennen kuin hakemuksen tila muuttuu tällä sivulla. \n\n Hakemuksesi käsittelyn nopeuttamiseksi ja tehostamiseksi pyydetään lähettämään liitteet ensisijaisesti tämän järjestelmän kautta. Jos lähetät tästä huolimatta hakemuksen liitteet (kopio riittää) tavallisella kirjepostilla, lähetä ne viikon kuluessa osoitteeseen: \n\nSuur-Savon Energiasäätiö sr \nc/o Ervasti Kalle Savilahdenkatu 5-7 B \n50100 Mikkeli \n \nLähetettyjä dokumentteja ei palauteta';
      break;

    case ApplicationStatus.SIGNED:
      applicationStatusMessage =
        'Hakemus on nyt allekirjoitettu ja se odottaa Suur-Savon Energiasäätiön käsittelyä. Hakemuksen käsittelyaika on 1-2 viikkoa, jonka jälkeen verkkoyhtiön tulee vielä todentaa paneelijärjestelmän kytkentä. Kytkentä voidaan todentaa, kun olet tehnyt **tuotannon verkkopalvelusopimuksen** Järvi-Suomen Energia Oy:n kanssa. Saat sähköpostiin ohjeet hakemuksen käsittelyn seuraamiseen.';
      break;

    case ApplicationStatus.APPROVED:
      applicationStatusMessage =
        'Suur-Savon Energiasäätiö on käsitellyt hakemuksen ja se on alustavasti hyväksytty. Asian siirtyminen maksatukseen edellyttää vielä, että Järvi-Suomen Energia Oy todentaa aurinkopaneelijärjestelmän kytkennän sähköverkkoon. Kytkentä voidaan todentaa, kun olet tehnyt **tuotannon verkkopalvelusopimuksen** Järvi-Suomen Energia Oy:n kanssa. Sopimuksen tekemisestä saamme tiedon Järvi-Suomen Energia Oy:ltä. Verkkoyhtiön todennuksen jälkeen tuki menee maksatukseen ilmoittamallesi pankkitilille noin kahden viikon kuluessa. \n\nMikäli järjestelmää ei ole hyväksytysti kytketty sähköverkkoon 4 kuukauden kuluessa hakemuksen alustavasta hyväksymisestä lukien, hakemus raukeaa.';
      break;

    case ApplicationStatus.REJECTED:
      applicationStatusMessage =
        'Hakemuksesi ei valitettavasti täyttänyt tuen maksatuksen ehtoja tai hyväksyttävien hakemusten enimmäismäärä on ylitetty, joten hakemus on hylätty.';
      break;

    case ApplicationStatus.FINALIZED:
      applicationStatusMessage =
        'Hakemuksesi on hyväksytty ja aurinkopaneelijärjestelmän kytkentä on todennettu. Tuki on siirretty maksatukseen ilmoittamallesi pankkitilille. Maksu tapahtuu noin 2 viikon kuluessa.';
      break;

    case ApplicationStatus.PAID:
      applicationStatusMessage =
        'Tukisumma on nyt maksettu ilmoittamallesi pankkitilille.';
      break;

    default:
      applicationStatusMessage = 'VIRHE STATUKSESSA';
      break;
  }
  return applicationStatusMessage;
};
