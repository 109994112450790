import axios from 'axios';

const devCloudUrl =
  'https://europe-west1-sses-dev-d3540.cloudfunctions.net/default/api/';
const localUrl =
  'http://localhost:5000/sses-dev-d3540/us-central1/default/api/';

let apiUrl = devCloudUrl;

if (CONFIG.build.environment === 'default') {
  apiUrl = localUrl;
} else if (CONFIG.build.environment === 'development') {
  apiUrl = devCloudUrl;
}

enum ApiPath {
  UPLOAD = 'upload',
  APPLY = 'apply',
  CHECK_COUNT = 'check_count'
}

const service = {
  upload: (formData: FormData) => {
    return axios(apiUrl + ApiPath.UPLOAD, {
      method: 'POST',
      data: formData
    });
  },
  apply: (formData: FormData) => {
    return axios(apiUrl + ApiPath.APPLY, {
      method: 'POST',
      data: formData
    });
  },
  checkCount: () => {
    return axios(apiUrl + ApiPath.CHECK_COUNT, {
      method: 'GET'
    });
  }
};

export default service;
