import * as React from 'react';
import Attachment from './Attachment';

interface AttachmentListProps {
  files?: object;
  fileUrls?: string[];
  removeAttachment?: (index: string) => void;
  resetFileInput?: () => void;
}

const AttachmentList: React.FunctionComponent<AttachmentListProps> = props => (
  <div className="attachment-list-container">
    {props.files ? (
      Object.keys(props.files).map(key => (
        <Attachment
          key={key}
          index={key}
          fileName={props.files[key].name}
          fileOriginalName={props.files[key].originalName}
          removeAttachment={props.removeAttachment}
          fileUrl={props.files[key].url}
          resetFileInput={props.resetFileInput}
        />
      ))
    ) : props.fileUrls ? (
      props.fileUrls.map(url => (
        <Attachment
          key={'new'}
          index={'new'}
          fileName={url}
          fileOriginalName={'Allekirjoitettu hakemus PDF'}
          fileUrl={url}
          resetFileInput={props.resetFileInput}
        />
      ))
    ) : (
      <></>
    )}
  </div>
);

export default AttachmentList;
