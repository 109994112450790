import * as React from 'react';
import './ApplicationSearch.css';
import { ApplicationStatus } from '../../../../sses-backend/types';
import { SearchParameters } from 'components/ApplicationManager';

interface ApplicationSearchProps {
  handleSearchParams: (event: React.ChangeEvent) => void;
  handleSearch: (event: React.MouseEvent | React.KeyboardEvent) => void;
  handleClearInputFields: (event: React.MouseEvent) => void;
  handleCSVExport: (event: React.MouseEvent) => void;
  searchParams?: SearchParameters;
}

class ApplicationSearch extends React.Component<ApplicationSearchProps> {
  public handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.props.handleSearch(e);
    }
  };

  public render() {
    return (
      <div className="tracker-content">
        <div className="row">
          <div className="col col-sm-12 col-md-12 col-lg-12">
            <div className="form-group row">
              <div className="col-sm-2 col-md-2">Vapaa haku</div>
              <div className="col-sm-3 col-md-3 ">
                <input
                  id="free-search"
                  className="form-control search-input"
                  type="text"
                  onChange={this.props.handleSearchParams}
                  placeholder="Anna hakusana"
                  value={this.props.searchParams.freeSearch}
                  onKeyPress={this.handleKeyPress}
                />
              </div>
              <div className="col-sm-3 col-md-4">Hae tilan perusteella</div>
              <div className="col-sm-4 col-md-3">
                <select
                  value={this.props.searchParams.filterByStatus}
                  id="status-selector"
                  className="form-control search-input"
                  onChange={this.props.handleSearchParams}>
                  <option value={ApplicationStatus.ALL}>KAIKKI</option>
                  <option value={ApplicationStatus.UNSIGNED}>
                    ODOTTAA ALLEKIRJOITUSTA
                  </option>
                  <option value={ApplicationStatus.SIGNED}>
                    ODOTTAA KÄSITTELYÄ
                  </option>
                  <option value={ApplicationStatus.APPROVED}>
                    ODOTTAA VERKKOYHTIÖTÄ
                  </option>
                  <option value={ApplicationStatus.REJECTED}>HYLÄTTY</option>
                  <option value={ApplicationStatus.FINALIZED}>
                    MAKSATUKSESSA
                  </option>
                  <option value={ApplicationStatus.PAID}>MAKSETTU</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2 col-md-2">Päivä</div>
              <div className="col-sm-4 col-md-4">
                <input
                  id="start-date"
                  type="date"
                  className="form-control search-input"
                  onChange={this.props.handleSearchParams}
                  value={this.props.searchParams.startDate}
                />
              </div>
              <div className="col-sm-1 col-md-1 col-dash">&#8212;</div>
              <div className="col-sm-4 col-md-4">
                <input
                  id="end-date"
                  type="date"
                  className="form-control search-input"
                  onChange={this.props.handleSearchParams}
                  value={this.props.searchParams.endDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <button
            className="btn btn-lg btn-primary buttons"
            onClick={this.props.handleSearch}>
            HAE
          </button>
          <button
            className="btn btn-lg btn-info buttons"
            onClick={this.props.handleClearInputFields}>
            PALAUTA
          </button>
          <button
            className="btn btn-lg btn-info buttons"
            onClick={this.props.handleCSVExport}>
            CSV EXPORT
          </button>
        </div>
      </div>
    );
  }
}

export default ApplicationSearch;
