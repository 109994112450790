import { File } from 'google-cloud__storage';
import { Timestamp, FieldValue } from '@google-cloud/firestore';

export const APPLICATION_LIMIT_WITHOUT_QUEUE: number = 215;

export const APPLICATION_LIMIT_WITH_QUEUE: number = 260;

export enum Schema {
  APPLICATIONFORMS = 'application_forms',
  FILEREFERENCES = 'file_references',
  USERS = 'users'
}

/**
 * Type used when generating a CSV from an array
 * of ApplicationForm objects in ApplicationManager
 * component.
 *
 * @author Bogdan
 */
export class ApplicationToExport {
  name: string;
  trackingId: string;
  streetAddress: string;
  postalCode: string;
  homeTown: string;
  panelElectricNumber: string;
  IBAN: string;
}

export class ApplicationForm {
  id?: string;
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  email?: string;
  phone?: string;
  IBAN?: string;
  networkCompany?: string;
  panelVendorName?: string;
  panelVendorId?: string;
  permanentUse?: boolean;
  agreementCheckbox?: boolean;
  additionalNotes?: string;
  files?: FileReference[];
  pdfUrl?: string;
  status?: ApplicationStatus;
  vismaDocumentId?: string;
  submissionDate?: Date | Timestamp | FieldValue;
  lastUpdate?: Date;
  applicationNumber?: number;
  applicantNumberInQueue?: number;
  signed?: boolean;
  trackingId?: string;
  invitation?: Invitation;
  panelElectricNumber?: string;
  attachmentsByMail?: boolean;
  homeTown?: string;
  postOffice?: string;
  postalCode?: string;
  subscriptionNumber?: string;

  constructor() {
    this.firstName = undefined;
    this.lastName = undefined;
    this.streetAddress = undefined;
    this.email = undefined;
    this.homeTown = undefined;
    this.phone = undefined;
    this.IBAN = undefined;
    this.networkCompany = undefined;
    this.panelVendorId = undefined;
    this.panelVendorName = undefined;
    this.subscriptionNumber = undefined;
    this.permanentUse = undefined;
    this.postalCode = undefined;
    this.postOffice = undefined;
    this.additionalNotes = undefined;
    this.permanentUse = undefined;
    this.panelElectricNumber = undefined;
    this.attachmentsByMail = undefined;
    this.agreementCheckbox = undefined;
  }
}

export enum ApplicationStatus {
  UNSIGNED,
  SIGNED,
  APPROVED,
  REJECTED,
  FINALIZED,
  PAID,
  ALL,
  ERROR
}

export class Invitation {
  uuid?: string;
  status?: string;
  passphrase?: string;

  public constructor() {
    this.uuid = null;
    this.status = null;
    this.passphrase = null;
  }
}

export interface FileReference {
  id?: string;
  originalName?: string;
  name?: string;
  url?: string;
  mimeType?: string;
  size?: string;
  date?: Date | Timestamp | FieldValue;
  bucketFile?: File;
}

export interface User {
  uuid: string;
  role: string;
}
