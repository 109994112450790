import * as React from 'react';

export interface FooterProps {
  companyName: string;
  address: string;
  postalIndex: string;
  phone: string;
}

const Footer: React.FunctionComponent<FooterProps> = props => (
  <div className="footer-container row top-bot-padding exo-font white">
    <span className="footer-line bold-font">{props.companyName} </span>
    <span className="footer-line">{props.address} </span>
    <span className="footer-line">{props.postalIndex} </span>
    <span className="footer-line">{props.phone} </span>
  </div>
);

export default Footer;
