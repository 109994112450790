import * as React from 'react';
import { Helmet } from 'react-helmet';
import faviconWebpackPlugin from 'favicons-webpack-plugin';
import favicon from '../assets/images/favicon.ico';
/**
 * Header content for the html page
 *
 * @author Ville Venäläinen
 */
export default class Head extends React.PureComponent {
  public render() {
    return (
      <Helmet>
        <title>Suur-Savon energiasäätiö &copy; Observis Oy 2019</title>
        <meta name="description" content="Mobile work force" />
        <meta name="theme-color" content="#008f68" />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.4.2/css/all.css"
          integrity="sha384-/rXc/GQVaYpyDdyxK+ecHPVYJSN9bmVFBvjA/9eOB+pb3F2w2N6fc5qB9Ew5yIns"
          crossOrigin="anonymous"
        />
        <link rel="shortcut icon" type="image/x-icon" href={favicon} />
        <script
          crossOrigin="anonymous"
          src="https://unpkg.com/react@16/umd/react.production.min.js"
        />
        <script
          crossOrigin="anonymous"
          src="https://unpkg.com/react-dom@16/umd/react-dom.production.min.js"
        />

        <script src="https://www.gstatic.com/firebasejs/5.5.7/firebase-app.js" />
        <script src="https://www.gstatic.com/firebasejs/5.5.7/firebase-auth.js" />
        <script src="https://www.gstatic.com/firebasejs/5.5.7/firebase-firestore.js" />

        <meta
          name="version"
          content={`${CONFIG.build.version} build: ${CONFIG.build.build}`}
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#fffbuild" />
        <meta name="application-name" content="Suomen eOppimiskeskus ry" />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta
          name="apple-mobile-web-app-title"
          content="Suomen eOppimiskeskus ry"
        />
      </Helmet>
    );
  }
}
