import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import '../../../../src/index.css';
import { Content } from 'react-bootstrap/lib/Tab';
interface ApplicationInputProps {
  type: INPUT_TYPE;
  name: string;
  labelText: string;
  handleChange: (event: React.ChangeEvent<any>) => void;
  formError?: string | null;
  orientation: INPUT_ORIENTATION;
  value?: string | number | string;
  checked?: boolean;
  disabled?: boolean;
  optionalClassName?: string;
}

export const enum INPUT_TYPE {
  TEXT_INPUT = 'text',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  TEXT_AREA = 'textarea'
}

export const enum INPUT_ORIENTATION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

const ApplicationInput: React.FunctionComponent<ApplicationInputProps> = (
  props: ApplicationInputProps
) => (
  <div
    className={
      'input-container row ' + props.orientation + ' ' + props.optionalClassName
    }>
    <label className={`exo-font col-sm-4 text-left`}>{props.labelText}</label>
    {props.type === 'text' || props.type === 'password' ? (
      <>
        <input
          className="col-sm-7 inputs"
          type={props.type}
          name={props.name}
          onChange={props.handleChange}
          value={props.value}
          disabled={props.disabled}
        />
        {props.formError ? (
          <p className="form-error-message">{props.formError}</p>
        ) : (
          <></>
        )}
      </>
    ) : props.type === 'checkbox' ? (
      <div className="checkbox">
        {props.checked ? (
          <Checkbox
            className="col-sm-1"
            name={props.name}
            onChange={props.handleChange}
            checked={true}
            disabled={props.disabled}
          />
        ) : (
          <Checkbox
            className="col-sm-1"
            name={props.name}
            onChange={props.handleChange}
            checked={false}
            disabled={props.disabled}
          />
        )}
        {props.formError ? (
          <p className="form-error-message">{props.formError}</p>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <>
        <textarea
          className="col-sm-7 textarea-input"
          name={props.name}
          onChange={props.handleChange}
          value={props.value}
          disabled={props.disabled}
        />
        {props.formError ? (
          <p className="form-error-message textarea-message">
            {props.formError}
          </p>
        ) : (
          <></>
        )}
      </>
    )}
  </div>
);

export default ApplicationInput;
