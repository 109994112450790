import * as React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import HomePage from 'containers/public/Home/Home';
import ApplicationForm from 'containers/public/ApplicationForm/ApplicationForm';
import BuildInfo from 'components/BuildInfo/index';
import ClientTrackingLogin from './components/ClientTrackingLogin/index';
import ApplicationSubmitted from './components/ApplicationSubmitted/index';
import TrackingComponent from './components/TrackingComponent/index';
import { NAVIGATION_PATH } from 'paths';
import history from './routerHistory';
import AuthComponent from 'components/AuthComponent';
import ApplicationManager from 'components/ApplicationManager';

const RouterClass = () => (
  <Router history={history}>
    <Switch>
      <Route exact={true} path={NAVIGATION_PATH.HOME} component={AuthComponent} />
      <Route path={NAVIGATION_PATH.APPLY} component={ApplicationForm} />
      <Route path={NAVIGATION_PATH.DEBUG} component={BuildInfo} />
      {/* <Route
        path={NAVIGATION_PATH.TRACK_LOGIN}
        component={ClientTrackingLogin}
      /> */}
      <Route path={NAVIGATION_PATH.SUCCESS} component={ApplicationSubmitted} />
      <Route
        exact={true}
        path={NAVIGATION_PATH.APPLICATION_MANAGER}
        component={ApplicationManager}
      />
      <Route path={NAVIGATION_PATH.AUTH} component={AuthComponent} />
      <Route
        path={NAVIGATION_PATH.TRACKING + ':email/:trackingId'}
        component={TrackingComponent}
      />
      <Route
        path={NAVIGATION_PATH.APPLICATION_MANAGER + ':trackingId'}
        component={ApplicationForm}
      />
    </Switch>
  </Router>
);

export default RouterClass;
