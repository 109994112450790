import { Redirect } from 'react-router';
import { NAVIGATION_PATH } from 'paths';
import history from '../routerHistory';

export function backToHome() {
  const r = confirm(
    'Tallennettamattomat tiedot menetetään.\nHaluatko varmasti poistua?'
  );
  if (r === true) {
    history.push(NAVIGATION_PATH.HOME);
  }
}

export function toLoginPage() {
  history.push(NAVIGATION_PATH.AUTH);
}
