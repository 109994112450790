export function isServerSide(): boolean {
  const p: any = process;
  if (p) {
    // Needed for development time debugging in vscode
    return p.title !== "browser";
  }
  return false;
}

export function isDevMode(): boolean {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}
