import * as React from 'react';
import '../../loader.scss';

export const setLoading = (status: boolean) => {
  if (status) {
    // window.scrollTo(0, 0);
    document.body.classList.add('no-scrolling');
    const solarPanels: HTMLElement | null = document.getElementById(
      'loading-solar-panels'
    );
    if (solarPanels != null) {
      solarPanels.classList.remove('invisible');
    }
  } else {
    document.body.classList.remove('no-scrolling');
    const solarPanels: HTMLElement | null = document.getElementById(
      'loading-solar-panels'
    );
    if (solarPanels != null) {
      solarPanels.classList.add('invisible');
    }
  }
};
export default class LoadingSolarPanels extends React.Component<any, any> {
  public render() {
    return (
      <div className="holder invisible" id="loading-solar-panels">
        <div className="flip-preloader example-3">
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}
