import * as React from 'react';
import './applicationList.css';
import ApplicationItem from './ApplicationItem';
import { ApplicationForm } from '../../../../sses-backend/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { NAVIGATION_PATH } from 'paths';
import { TableSortLabel } from '@material-ui/core';
import '../../../src/index.css';

interface ApplicationListProps extends RouteComponentProps {
  applications: ApplicationForm[];
  handleOrderParams: (event: React.MouseEvent<Element>) => void;
}
class ApplicationList extends React.Component<ApplicationListProps> {
  public handleItemClick = (event: React.MouseEvent<Element>) => {
    const trackingId: string = event.currentTarget.id;
    if (trackingId) {
      this.props.history.push(NAVIGATION_PATH.APPLICATION_MANAGER + trackingId);
    }
  };

  public render() {
    return (
      <div className="applicationListContent">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className="sorting-header"
                  scope="col"
                  id="applicantNumberInQueue"
                  onClick={this.props.handleOrderParams}>
                  #
                </th>
                <th
                  className="sorting-header"
                  scope="col"
                  id="lastName"
                  onClick={this.props.handleOrderParams}>
                  Hakijan nimi
                </th>
                <th
                  className="sorting-header"
                  scope="col"
                  id="trackingId"
                  onClick={this.props.handleOrderParams}>
                  Numero
                </th>
                <th
                  className="sorting-header"
                  scope="col"
                  id="submissionDate"
                  onClick={this.props.handleOrderParams}>
                  Hakupäivä
                </th>
                <th
                  className="sorting-header"
                  scope="col"
                  id="status"
                  onClick={this.props.handleOrderParams}>
                  Tila
                </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {this.props.applications.map(application => {
                return (
                  <ApplicationItem
                    key={application.id}
                    index={application.id}
                    application={application}
                    handleItemClick={this.handleItemClick}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(ApplicationList);
