import * as React from 'react';
import * as firebase from 'firebase';
import Heading from 'components/Heading';
import FileUploader from './FileUploader';
import { Button } from 'react-bootstrap';
import service from '../../../services/apiService';
import ApplicationInput, {
  INPUT_TYPE,
  INPUT_ORIENTATION
} from './ApplicationInput';
import {
  ApplicationStatus,
  ApplicationForm,
  APPLICATION_LIMIT_WITH_QUEUE
} from '../../../../../sses-backend/types';
import { validateApplication } from '../../../../../sses-backend/utils/formValidation';
import { NAVIGATION_PATH } from 'paths';
import { setLoading } from 'components/LoadingSolarPanels';
import { RouteComponentProps } from 'react-router';
import { db } from 'firebaseApp';
import AttachmentList from './AttachmentList';
import Attachment from './Attachment';
import ApplicationStatusUpdate from 'components/ApplicationStatusUpdate';
import { checkApplicationStatus } from '../../../utils/sortingUtils';
import { FileReference } from '../../../../../sses-backend/types';
import Swal from 'sweetalert2';
import { detect } from 'detect-browser';

export interface ApplicationFormState {
  application?: ApplicationForm;
  homeTown?: string;
  postOffice?: string;
  postalCode?: string;
  formErrors?: any;
  userLoggedIn?: boolean;
  applicationPeriodOpen?: boolean;
}

export interface Invitation {
  passphrase: string;
  status: string;
  uuid: string;
}

class ApplicationFormComponent extends React.Component<
  RouteComponentProps,
  ApplicationFormState
> {
  public unsubscribe: () => void;

  public state: ApplicationFormState = {
    application: {
      firstName: '',
      lastName: '',
      streetAddress: '',
      email: '',
      phone: '',
      IBAN: '',
      networkCompany: '',
      panelVendorName: '',
      panelVendorId: '',
      permanentUse: false,
      additionalNotes: '',
      panelElectricNumber: '',
      attachmentsByMail: false,
      agreementCheckbox: false,
      homeTown: '',
      postOffice: '',
      postalCode: '',
      subscriptionNumber: ''
    },
    applicationPeriodOpen: true,
    userLoggedIn: false
  };

  public componentDidMount = async () => {
    const params = this.props.match.params as any;

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ userLoggedIn: true });
      } else {
        this.setState({ userLoggedIn: false });
        if (this.props.match.path.includes('/manage/')) {
          /** Prevent unauthorized users from directly accessing manage urls */
          this.props.history.push(NAVIGATION_PATH.HOME);
        }
      }
    });

    if (this.props.match.params.hasOwnProperty('trackingId')) {
      const trackingIdParam: string = params.trackingId;
      if (trackingIdParam) {
        const application = db
          .collection('application_forms')
          .where('trackingId', '==', trackingIdParam)
          .limit(1)
          .get();
        setLoading(true);
        const responses = await application;
        setLoading(false);
        this.setState({ application: responses.docs[0].data() });

        this.unsubscribe = db
          .collection('application_forms')
          .doc(responses!.docs[0].id)
          .onSnapshot(doc => {
            this.setState({ application: doc.data() });
          });
      }
    }

    setLoading(true);
    service
      .checkCount()
      .then(response => {
        // response.data is the current number of signed applications
        const currentSignedCount = response.data;
        if (response.data >= APPLICATION_LIMIT_WITH_QUEUE) {
          this.setState({ applicationPeriodOpen: false });
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  public componentWillUnmount = () => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  };

  public formErrorsExist = () => {
    if (this.state.formErrors) {
      if (this.state.formErrors.length === 0) {
        return false;
      }
    }
    return true;
  };

  public getFormErrorForField = (fieldName: string) => {
    if (
      this.state.application.hasOwnProperty(fieldName) &&
      this.state.formErrors
    ) {
      for (const formError of this.state.formErrors!) {
        if (formError.path === fieldName) {
          return formError.message;
        }
      }
    }
  };

  public handleChange = (event: React.ChangeEvent<any>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const updatedApplication = { ...this.state.application };
    // Saving modified property to state
    updatedApplication[event.currentTarget.name] = value;
    // Running form validation
    const formErrors = validateApplication({ ...updatedApplication });
    this.setState({ application: updatedApplication, formErrors });
  };

  public handleFileUpload = (uploadedFiles: FileList | null) => {
    if (uploadedFiles) {
      const files: FileReference[] = { ...this.state.application.files };
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles.item(i) != null) {
          const newFile: File = uploadedFiles.item(i)!;
          files[Date.now() + uploadedFiles.item(i).name] = newFile;
        }
      }

      const application: ApplicationForm = { ...this.state.application };
      application.files = files;
      this.setState({ application });
    }
  };

  public removeAttachment = (key: string) => {
    const application = { ...this.state.application };
    delete application.files[key];
    this.setState({ application });
  };

  public buildAddress = (
    address: string,
    postOffice: string,
    postalCode: string
  ): string => {
    return address + ' ' + postOffice + ' ' + postalCode;
  };

  public submit = () => {
    const formData: FormData = new FormData();
    const application: ApplicationForm = { ...this.state.application };
    formData.append('firstName', application.firstName!);
    formData.append('lastName', application.lastName!);
    formData.append('email', application.email!);
    formData.append('phone', application.phone!);
    formData.append('IBAN', application.IBAN!);
    formData.append('homeTown', application.homeTown);
    formData.append('networkCompany', application.networkCompany!);
    formData.append('panelVendorName', application.panelVendorName!);
    formData.append('panelVendorId', application.panelVendorId!);
    if (application.subscriptionNumber! === '') {
      formData.append('subscriptionNumber', '--');
    } else {
      formData.append('subscriptionNumber', application.subscriptionNumber!);
    }
    formData.append('permanentUse', application.permanentUse!.valueOf() + '');
    formData.append('additionalNotes', application.additionalNotes!);
    formData.append('panelElectricNumber', application.panelElectricNumber);
    formData.append('streetAddress', application.streetAddress!);
    formData.append('postOffice', application.postOffice!);
    formData.append('postalCode', application.postalCode!);
    formData.append(
      'attachmentsByMail',
      application.attachmentsByMail!.valueOf() + ''
    );
    formData.append(
      'agreementCheckbox',
      application.agreementCheckbox!.valueOf() + ''
    );
    // const generatedAddress: string = this.buildAddress(
    //   application.address,
    //   this.state.postOffice,
    //   this.state.postalCode
    // );
    // formData.append('address', generatedAddress!);

    if (application.files) {
      Object.keys(application.files!).map(key =>
        formData.append(key, application.files[key]!)
      );
    }

    setLoading(true);

    service
      .apply(formData)
      .then(response => {
        setLoading(false);
        const invitation: Invitation | undefined = response.data.invitation;
        this.props.history.push(
          NAVIGATION_PATH.TRACKING +
            response.data.email +
            '/' +
            response.data.trackingId
        );
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  public handleSumbit = () => {
    Swal.fire({
      title: 'Huomautus',
      html:
        'Lomaketta ei voi enää muokata lähettämisen jälkeen., </br> Voit kuitenkin ladata liitteeksi uusia tiedostoja myöhemmin.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Lähetä',
      cancelButtonText: 'Peruuta'
    }).then(result => {
      if (result.value) {
        this.submit();
      }
    });
  };

  public handleStatusChange = (statusChanged: ApplicationStatus) => async e => {
    const collectionRef = db.collection('application_forms');
    const querySnapshot = await collectionRef
      .where('trackingId', '==', this.state.application.trackingId)
      .get();
    const applicationDocument = querySnapshot.docs[0];

    collectionRef.doc(applicationDocument.id).update({
      status: statusChanged
    });
  };

  public updateStatusInFirestore = async (
    applicationEventValue: ApplicationStatus
  ) => {
    const collectionRef = db.collection('application_forms');
    const querySnapshot = await collectionRef
      .where('trackingId', '==', this.state.application.trackingId)
      .get();
    const applicationDocument = querySnapshot.docs[0];

    collectionRef
      .doc(applicationDocument.id)
      .update({
        status: applicationEventValue
      })
      .catch(error => {
        Swal.fire({
          title: 'Virhe',
          html:
            'Virhe päivitettäessä tietokantaa <br/> yritä myöhemmin uudelleen.',
          type: 'error'
        });
      });
  };

  public handleStatusChangeSelect = async (event: React.ChangeEvent<any>) => {
    const applicationEventValue: ApplicationStatus = +event.target.value;

    Swal.fire({
      title: 'Hakemuksen tilan vaihto',
      html: `Muokkaat hakemuksen ${
        this.state.application.trackingId
      } tilaa. </br></br>
      Vanha tila: ${checkApplicationStatus(
        this.state.application.status
      )} </br></br>
      Valittu tila: ${checkApplicationStatus(applicationEventValue)}`,
      type: 'info',
      showCancelButton: true,
      confirmButtonText: 'Hyväksy',
      cancelButtonText: 'Peruuta'
    }).then(result => {
      if (result.value) {
        this.updateStatusInFirestore(applicationEventValue);
      }
    });
  };
  public render() {
    const application: ApplicationForm = { ...this.state.application };
    const browser = detect();
    return (
      <>
        {browser && browser.name === 'ie' ? (
          <div className="browser-support-warning">
            <p>
              Hakemusta ei voi tehdä Internet Explorer -selaimella. Ole hyvä ja
              käytä Mozilla Firefox tai Google Chrome -selainta
            </p>
          </div>
        ) : (
          <></>
        )}
        {this.state.applicationPeriodOpen || this.state.userLoggedIn ? (
          <>
            {this.state.userLoggedIn ? (
              <Heading text="Hakemuksen tiedot" />
            ) : (
              <Heading text="Täytä tietosi" />
            )}
            <div className="application-edit">
              {this.state.userLoggedIn ? (
                <>
                  <h3 className="text-left sub-titles">Tilauksen status</h3>
                  <div className="input-container row">
                    <label className="exo-font col-sm-4 text-left">
                      STATUS
                    </label>
                    <select
                      onChange={this.handleStatusChangeSelect}
                      value={this.state.application.status}
                      className="col-sm-7 inputs select-box">
                      <option
                        disabled={true}
                        value={ApplicationStatus.UNSIGNED}>
                        ODOTTAA ALLEKIRJOITUSTA
                      </option>
                      <option
                        disabled={
                          this.state.application.status ===
                          ApplicationStatus.UNSIGNED
                        }
                        value={ApplicationStatus.SIGNED}>
                        ODOTTAA KÄSITTELYÄ
                      </option>
                      <option
                        disabled={
                          this.state.application.status ===
                          ApplicationStatus.UNSIGNED
                        }
                        value={ApplicationStatus.APPROVED}>
                        ODOTTAA VERKKOYHTIÖTÄ
                      </option>
                      <option
                        disabled={
                          this.state.application.status ===
                          ApplicationStatus.UNSIGNED
                        }
                        value={ApplicationStatus.REJECTED}>
                        HYLÄTTY
                      </option>
                      <option
                        disabled={
                          this.state.application.status ===
                          ApplicationStatus.UNSIGNED
                        }
                        value={ApplicationStatus.FINALIZED}>
                        MAKSATUKSESSA
                      </option>
                      <option
                        disabled={
                          this.state.application.status ===
                          ApplicationStatus.UNSIGNED
                        }
                        value={ApplicationStatus.PAID}>
                        MAKSETTU
                      </option>
                    </select>
                  </div>
                </>
              ) : (
                ''
              )}

              <h3 className="text-left sub-titles">Hakijan tiedot</h3>
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="firstName"
                labelText="Etunimi"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('firstName')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.firstName}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="lastName"
                labelText="Sukunimi"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('lastName')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.lastName}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="email"
                labelText="E-Mail"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('email')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.email}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="phone"
                labelText="Puhelin"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('phone')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.phone}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="IBAN"
                labelText="Tilinumero (IBAN)"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('IBAN')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.IBAN}
                disabled={this.state.userLoggedIn}
              />
              <h3 className="text-left sub-titles">
                Kohteen (käyttöpaikan) tiedot
              </h3>
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="homeTown"
                labelText="Kunta"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('homeTown')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.homeTown}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="streetAddress"
                labelText="Katuosoite"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('streetAddress')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.streetAddress}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="postalCode"
                labelText="Postinumero"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('postalCode')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.postalCode}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="postOffice"
                labelText="Toimipaikka"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('postOffice')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.postOffice}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="networkCompany"
                labelText="Verkkoyhtiön nimi"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('networkCompany')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.networkCompany}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="panelElectricNumber"
                labelText="Käyttöpaikan numero"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('panelElectricNumber')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.panelElectricNumber}
                disabled={this.state.userLoggedIn}
              />
              {application.subscriptionNumber !== null &&
              application.subscriptionNumber !== '' &&
              application.subscriptionNumber !== '--' ? (
                <ApplicationInput
                  type={INPUT_TYPE.TEXT_INPUT}
                  name="subscriptionNumber"
                  labelText="Liittymänumero"
                  handleChange={this.handleChange}
                  formError={this.getFormErrorForField('subscriptionNumber')}
                  orientation={INPUT_ORIENTATION.HORIZONTAL}
                  value={application.subscriptionNumber}
                  disabled={this.state.userLoggedIn}
                />
              ) : (
                <></>
              )}
              <ApplicationInput
                type={INPUT_TYPE.CHECKBOX}
                name="permanentUse"
                labelText="Vakuutan kohteen olevan vakituisessa asuinkäytössä"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('permanentUse')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                checked={application.permanentUse}
                disabled={this.state.userLoggedIn}
                optionalClassName="permanentUse"
              />
              <h3 className="text-left sub-titles">
                Aurinkosähköjärjestelmän toimittajan tiedot
              </h3>
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="panelVendorName"
                labelText="Toimittajan nimi"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('panelVendorName')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.panelVendorName}
                disabled={this.state.userLoggedIn}
              />
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="panelVendorId"
                labelText="Toimittajan y-tunnus"
                handleChange={this.handleChange}
                formError={this.getFormErrorForField('panelVendorId')}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={application.panelVendorId}
                disabled={this.state.userLoggedIn}
              />
              {/* {this.state.userLoggedIn ? (
            <>
              <ApplicationInput
                type={INPUT_TYPE.TEXT_INPUT}
                name="status"
                labelText="STATUS"
                handleChange={this.handleChange}
                orientation={INPUT_ORIENTATION.HORIZONTAL}
                value={checkApplicationStatus(application.status)}
                disabled={this.state.userLoggedIn}
              />
            </>
          ) : (
            <></>
          )} */}
              <br />
              <h5 className="header-title text-left application-line">
                Kerro meille hieman lisää kohteestasi
              </h5>
              <div className="exo-font">
                <ApplicationInput
                  type={INPUT_TYPE.TEXT_AREA}
                  name="additionalNotes"
                  labelText="Kohteen kuvaus"
                  handleChange={this.handleChange}
                  formError={this.getFormErrorForField('additionalNotes')}
                  orientation={INPUT_ORIENTATION.HORIZONTAL}
                  value={application.additionalNotes}
                  disabled={this.state.userLoggedIn}
                />
              </div>
            </div>

            {this.state.userLoggedIn &&
            application.status !== ApplicationStatus.ALL &&
            application.status !== ApplicationStatus.ERROR &&
            application.status !== ApplicationStatus.UNSIGNED ? (
              <>
                <Heading text="Allekirjoitetut dokumentit" />
                <AttachmentList fileUrls={[application.pdfUrl]} />
              </>
            ) : (
              <></>
            )}

            <Heading text="Lisää tiedostoja" />

            {this.state.userLoggedIn ? (
              <AttachmentList files={application.files} />
            ) : (
              <>
                <p className="exo-font application-attachment-info">
                  <em>
                    Lataa hakemuksen liitteeksi käyttöpaikkaa koskeva
                    aurinkopaneelijärjestelmän kirjallinen tarjous,
                    tilausvahvistus tai toimitussopimus. Tiedostot voivat olla
                    .pdf, .jpeg, .gif, .png tai .doc -formaateissa
                  </em>
                </p>

                <FileUploader
                  files={application.files}
                  handleFileUpload={this.handleFileUpload}
                  removeAttachment={this.removeAttachment}
                />
                <ApplicationInput
                  type={INPUT_TYPE.CHECKBOX}
                  name="attachmentsByMail"
                  labelText="Lähetän liitteet tavallisella kirjepostilla. Toimitusosoite näkyy seuraavalla sivulla lomakkeen lähettämisen jälkeen."
                  handleChange={this.handleChange}
                  formError={this.getFormErrorForField('attachmentsByMail')}
                  orientation={INPUT_ORIENTATION.HORIZONTAL}
                  checked={application.attachmentsByMail}
                  disabled={this.state.userLoggedIn}
                  optionalClassName="formCheckboxInput"
                />
                <ApplicationInput
                  type={INPUT_TYPE.CHECKBOX}
                  name="agreementCheckbox"
                  labelText="Hakemuksen jättämällä annan suostumukseni sille, että Järvi-Suomen Energia Oy voi ilmoittaa Suur-Savon Energiasäätiölle tuotannon verkkopalvelusopimuksen solmimisesta sähköyhtiön kanssa."
                  handleChange={this.handleChange}
                  formError={this.getFormErrorForField('agreementCheckbox')}
                  orientation={INPUT_ORIENTATION.HORIZONTAL}
                  checked={application.agreementCheckbox}
                  optionalClassName="formCheckboxInput"
                />
                {browser && browser.name !== 'ie' ? (
                  <>
                    <Button
                      disabled={this.formErrorsExist()}
                      className="btn btn-success exo-font AppButton AppBottomButton"
                      onClick={this.handleSumbit}>
                      Lähetä <i className="material-icons send-icon">send</i>
                    </Button>
                    <br />
                    {this.formErrorsExist() ? (
                      <label
                        style={{ display: 'inline-block' }}
                        className="button-disabled-message">
                        Täydennä puuttuvat tiedot jatkaaksesi!
                      </label>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <p className="exo-font red-text">
                    Hakemusta ei voi tehdä Internet Explorer -selaimella. Ole
                    hyvä ja käytä Mozilla Firefox tai Google Chrome -selainta
                  </p>
                )}
              </>
            )}
          </>
        ) : (
          <h3>
            Hakemuslomake on suljettu! Enimmäishakemusmäärä on saavutettu.
          </h3>
        )}
      </>
    );
  }
}

export default ApplicationFormComponent;
