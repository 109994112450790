import * as React from 'react';
import Heading from '../Heading';
// import { firebaseuiRef } from '../../firebaseApp';
import * as firebase from 'firebase';
// import * as firebaseui from 'firebaseui';
import { setLoading } from 'components/LoadingSolarPanels';
import { validateUserLoginInfo } from '../../../../sses-backend/utils/formValidation';

import './AuthComponent.css';
import { NAVIGATION_PATH } from 'paths';
import ApplicationInput, {
  INPUT_TYPE,
  INPUT_ORIENTATION
} from 'containers/public/ApplicationForm/ApplicationInput';

interface AuthComponentState {
  email?: string;
  password?: string;
  formErrors?: any;
  loginError?: string;
}

export default class AuthComponent extends React.Component<
  any,
  AuthComponentState
> {
  public state: AuthComponentState = {};

  public componentDidMount = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.props.history.push(NAVIGATION_PATH.APPLICATION_MANAGER);
      }
    });

    // setLoading(true);
    // firebaseuiRef.start('#firebase-auth-container', {
    //   credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    //   callbacks: {
    //     signInSuccessWithAuthResult: (authResult, redirectUrl) => {
    //       console.log(authResult.additionalUserInfo.isNewUser);
    //       if (authResult.additionalUserInfo.isNewUser) {
    //         this.props.history.push(NAVIGATION_PATH.AUTH);
    //       } else {
    //         this.props.history.push(NAVIGATION_PATH.APPLICATION_MANAGER);
    //       }
    //       return false;
    //     },
    //     uiShown: () => {
    //       setLoading(false);
    //     }
    //   },
    //   signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID]
    // });
  };

  public handleChange = (event: React.ChangeEvent<any>) => {
    const updatedState = { ...this.state };
    updatedState[event.target.name] = event.target.value;
    const formErrors = validateUserLoginInfo(updatedState);
    updatedState[`formErrors`] = formErrors;
    this.setState(updatedState);
  };

  public handleLogIn = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .catch(error => {
        if (error !== null && error.message !== null) {
          this.setState({ loginError: error.message });
        } else {
          this.setState({ loginError: 'Kirjautumisvirhe' });
        }
      });
  };

  public handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.handleLogIn();
  };

  public getFormErrorForField = (fieldName: string) => {
    if (this.state.hasOwnProperty(fieldName) && this.state.formErrors) {
      for (const formError of this.state.formErrors!) {
        if (formError.path === fieldName) {
          return formError.message;
        }
      }
    }
  };

  public render() {
    return (
      <div className="exo-font">
        <Heading text="Kirjaudu sisään" />
        {/* <div className="AllContent">
          <div id="firebase-auth-container" className="firebase-container" />
        </div> */}
        <form onSubmit={this.handleSubmit}>
          <ApplicationInput
            type={INPUT_TYPE.TEXT_INPUT}
            name="email"
            labelText="Sähköpostiosoite"
            handleChange={this.handleChange}
            formError={this.getFormErrorForField('email')}
            orientation={INPUT_ORIENTATION.VERTICAL}
            disabled={false}
          />
          <ApplicationInput
            type={INPUT_TYPE.PASSWORD}
            name="password"
            labelText="Salasana"
            handleChange={this.handleChange}
            formError={this.getFormErrorForField('password')}
            orientation={INPUT_ORIENTATION.VERTICAL}
            disabled={false}
          />
          <div className="user-login-button">
            <button
              type="submit"
              className="btn btn-primary btn-lg submit-button"
              disabled={
                !this.state.formErrors || this.state.formErrors.length !== 0
              }
              onClick={this.handleLogIn}>
              Kirjaudu
            </button>
            {this.state.loginError != null && this.state.loginError !== '' ? (
              <p className="login-error-message">{this.state.loginError}</p>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    );
  }
}
