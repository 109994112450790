/**
 * # FirebaseApp.ts
 *
 * Configuration for the Firebase app.
 *
 * @author Ville Venäläinen
 */

import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { isServerSide } from 'utils/devUtils';
import * as firebaseui from 'firebaseui';

/**
 * Note that firebase modules must be included as external scripts on App.tsx's helmet script
 */
const firebaseApp = firebase.initializeApp(CONFIG.firebase);

(window as any).firebase = firebase;

/**
 * Storage service reference
 */
export const storageService = firebaseApp.storage();
/**
 * instance of storage reference
 */
export const storageRef = storageService.ref();

export const firebaseuiRef = new firebaseui.auth.AuthUI(firebase.auth());

export const db: firebase.firestore.Firestore = firebase.firestore(firebaseApp);

firebase.firestore().settings({
  timestampsInSnapshots: true
});

if (!isServerSide()) {
  firebaseApp
    .firestore()
    .enablePersistence()
    .catch((err: any) => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.error(
          'Unable to active persistence because of multiple instances of application.'
        );
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.error(
          'Unable to active persistence because of legacy browser.'
        );
      }
    })
    .then(() => {
      console.log('firestore persistence enabled.');
    });
}

export { firebase };

export default firebaseApp;
