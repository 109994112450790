import * as React from 'react';

export interface HeadingProps {
  text: string;
}

const Heading: React.FunctionComponent<HeadingProps> = props => (
  <div className="heading-container white-background">
    <span className="heading-text">{props.text}</span>
  </div>
);

export default Heading;
