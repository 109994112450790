import * as firebase from 'firebase';
import * as React from 'react';
import Heading from 'components/Heading';
import ApplicationSearch from '../../containers/adminTracking/ApplicationSearch';
import ApplicationList from '../../containers/adminTracking/ApplicationList';
import {
  ApplicationForm,
  ApplicationStatus,
  ApplicationToExport
} from '../../../../sses-backend/types';
import { db } from '../../firebaseApp';
import { setLoading } from 'components/LoadingSolarPanels';
import {
  sortApplications,
  filterByStatus,
  filterByStartDate,
  filterByEndDate,
  filterByString
} from 'utils/sortingUtils';
import { OrderByDirection } from '@google-cloud/firestore';
import { json2csv, Parser } from 'json2csv';
import { NAVIGATION_PATH } from 'paths';

const csvFields: json2csv.Options<{}> = {
  withBOM: true,
  fields: [
    {
      label: 'Nimi',
      value: 'name'
    },
    {
      label: 'Hakemuksen numero',
      value: 'trackingId'
    },
    {
      label: 'Katuosoite',
      value: 'streetAddress'
    },
    {
      label: 'Postinumero',
      value: 'postalCode'
    },
    {
      label: 'Kunta',
      value: 'homeTown'
    },
    {
      label: 'Käyttöpaikan numero',
      value: 'panelElectricNumber'
    },
    {
      label: 'Tilinumero (IBAN)',
      value: 'IBAN'
    }
  ]
};

interface ApplicationManagerState {
  applications?: ApplicationForm[];
  searchParameters?: SearchParameters;
  orderParameters?: OrderParameters;
  userLoggedIn: boolean;
}

export interface SearchParameters {
  freeSearch: string;
  filterByStatus: ApplicationStatus | null;
  startDate: string;
  endDate: string;
}

interface OrderParameters {
  applicantNumberInQueueAsc?: boolean;
  nameOrderAsc?: boolean;
  numberOrderAsc?: boolean;
  submissionDateOrderAsc?: boolean;
  statusOrderAsc?: boolean;
}

class ApplicationManager extends React.Component<any, ApplicationManagerState> {
  public unsubscribe: () => void;

  public state: ApplicationManagerState = {
    searchParameters: {
      freeSearch: '',
      filterByStatus: ApplicationStatus.ALL,
      startDate: '',
      endDate: ''
    },
    userLoggedIn: false
  };

  public downloadFile = (fileName: string, file: string) => {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:application/octet-stream,' + encodeURIComponent(file)
    );
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  public handleCSVExport = () => {
    const applicationsToExport: ApplicationToExport[] = [];
    const selectedApplications: ApplicationForm[] = [
      ...this.state.applications
    ];
    if (selectedApplications) {
      selectedApplications.map(application => {
        const applicationToExport: ApplicationToExport = {
          name: application.lastName + ' ' + application.firstName,
          trackingId: application.trackingId,
          streetAddress: application.streetAddress,
          postalCode: application.postalCode,
          homeTown: application.homeTown,
          panelElectricNumber: application.panelElectricNumber,
          IBAN: application.IBAN
        };
        applicationsToExport.push(applicationToExport);
      });

      const json2CsvParser = new Parser(csvFields);
      const csv: string = json2CsvParser.parse(applicationsToExport);
      this.downloadFile('applications_export.csv', csv);
    }
  };

  public search = async () => {
    const searchParams = { ...this.state.searchParameters };

    const collectionRef = db.collection('application_forms');
    const response = await collectionRef.get();
    if (response) {
      let applications: ApplicationForm[] = [];

      response.docs.map(doc => {
        applications.push(doc.data() as ApplicationForm);
      });

      if (searchParams.filterByStatus) {
        applications = filterByStatus(
          applications,
          +searchParams.filterByStatus
        );
      }

      if (searchParams.startDate) {
        applications = filterByStartDate(
          applications,
          new Date(searchParams.startDate)
        );
      }

      if (searchParams.endDate) {
        applications = filterByEndDate(
          applications,
          new Date(searchParams.endDate)
        );
      }

      if (searchParams.freeSearch) {
        applications = filterByString(applications, searchParams.freeSearch);
      }

      this.setState({ applications });
    }
  };

  public handleSearch = async (
    event: React.MouseEvent<Element> | React.KeyboardEvent
  ) => {
    this.search();
  };

  public handleClearInputFields = (): void => {
    const searchParameters: SearchParameters = {
      freeSearch: '',
      filterByStatus: ApplicationStatus.ALL,
      startDate: '',
      endDate: ''
    };
    this.setState({ searchParameters }, this.search);
  };

  public handleSearchParams = (event: React.ChangeEvent<any>) => {
    const searchParams: SearchParameters = { ...this.state.searchParameters };

    if (event.currentTarget.id === 'status-selector') {
      searchParams.filterByStatus = event.currentTarget
        .value as ApplicationStatus;
    } else if (event.currentTarget.id === 'free-search') {
      searchParams.freeSearch = event.currentTarget.value as string;
    } else if (event.currentTarget.id === 'start-date') {
      searchParams.startDate = event.currentTarget.value as string;
    } else if (event.currentTarget.id === 'end-date') {
      searchParams.endDate = event.currentTarget.value as string;
    }

    this.setState({ searchParameters: searchParams });
  };

  public handleOrderParams = async (event: React.MouseEvent<Element>) => {
    const oldOrderParams: OrderParameters = {
      ...this.state.orderParameters
    };
    const newOrderParams: OrderParameters = {};

    let requiredOrder: OrderByDirection = 'asc';

    if (event.currentTarget.id === 'applicantNumberInQueue') {
      newOrderParams.applicantNumberInQueueAsc = !oldOrderParams.applicantNumberInQueueAsc;
      if (!newOrderParams.applicantNumberInQueueAsc) {
        requiredOrder = 'desc';
      }
    } else if (event.currentTarget.id === 'lastName') {
      newOrderParams.nameOrderAsc = !oldOrderParams.nameOrderAsc;
      if (!newOrderParams.nameOrderAsc) {
        requiredOrder = 'desc';
      }
    } else if (event.currentTarget.id === 'trackingId') {
      newOrderParams.numberOrderAsc = !oldOrderParams.numberOrderAsc;
      if (!newOrderParams.numberOrderAsc) {
        requiredOrder = 'desc';
      }
    } else if (event.currentTarget.id === 'submissionDate') {
      newOrderParams.submissionDateOrderAsc = !oldOrderParams.submissionDateOrderAsc;
      if (!newOrderParams.submissionDateOrderAsc) {
        requiredOrder = 'desc';
      }
    } else if (event.currentTarget.id === 'status') {
      newOrderParams.statusOrderAsc = !oldOrderParams.statusOrderAsc;
      if (!newOrderParams.statusOrderAsc) {
        requiredOrder = 'desc';
      }
    }

    this.setState({
      applications: sortApplications(
        [...this.state.applications],
        event.currentTarget.id,
        requiredOrder
      ),
      orderParameters: newOrderParams
    });
  };

  public componentDidMount = async () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ userLoggedIn: true });
      } else {
        this.setState({ userLoggedIn: false });
        this.props.history.push(NAVIGATION_PATH.HOME);
      }
    });

    setLoading(true);
    this.unsubscribe = db
      .collection('application_forms')
      .onSnapshot(response => {
        const applications: ApplicationForm[] = [];
        response.docs.map(doc => applications.push(doc.data()));
        this.setState({ applications }, this.search);
      });
    setLoading(false);
  };

  public componentWillUnmount = () => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  };
  public render() {
    return (
      <div className="admin-view-content exo-font">
        <Heading text="Saapuneet hakemukset" />

        <ApplicationSearch
          searchParams={this.state.searchParameters}
          handleClearInputFields={this.handleClearInputFields}
          handleSearchParams={this.handleSearchParams}
          handleSearch={this.handleSearch}
          handleCSVExport={this.handleCSVExport}
        />
        {this.state.applications ? (
          <ApplicationList
            applications={this.state.applications}
            handleOrderParams={this.handleOrderParams}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ApplicationManager;
