import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import * as React from 'react';
import { render } from 'react-dom';
import Footer from 'components/Footer';
import Head from 'components/Header';
import registerServiceWorker from './registerServiceWorker';
import LoadingSolarPanels from 'components/LoadingSolarPanels/index';
import Router from './Router';
import './index.css';
import './fonts.css';
import TopHeader from './components/TopHeader/index';
import './assets/images/favicon.ico';

render(
  <>
    <LoadingSolarPanels />
    {/* <Head /> */}
    <div className="container">
      <div className="col col-sm-8 col-sm-offset-2">
        <Head />
        <TopHeader />
        <div className="row white-transparent-background">
          <Router />
        </div>
        <Footer
          companyName="Suur-Savon Energiasäätiö"
          address="Savilahdenkatu 5-7 B 32"
          postalIndex="50100 Mikkeli"
          phone="0440 365 333"
        />
      </div>
    </div>
  </>,
  document.querySelector('#main')
);

registerServiceWorker();
