import * as React from 'react';
import Heading from '../Heading';
import './ApplicationSubmitted.css';

export interface ApplicationSubmittedProps {
  vismaUrl: string;
}

class ApplicationSubmitted extends React.Component<ApplicationSubmittedProps> {
  public render() {
    return (
      <div>
        <Heading text="Valmista!" />
        <h4 className="text-header">Olemme vastaanottaneet hakemuksesi</h4>
        <div className="allContent exo-font">
          <p>
            Käsittelemme hakemukset noin kahden viikon sisällä lähetyspäivästä.
            Olemme teihin yhteydessä mikäli tarvitsemme selvennyksiä tai
            lisätietoa.
          </p>
          <p>
            Lähetämme piakkoin kirjatumistiedot antamaasi
            sähköpostiosoitteeseen. Linkin kautta pääsaet katsomaan hyväksymisen
            tilaa, sekä hyväksymisen jälkeen voit toimittaa todistukset ja
            dokumentit päätöksen viimeistelemiseksi.
          </p>
          <p>
            Mikäli haluat muuttaa, tai lisätä jotain hakemukseesi ota meihin
            yhteyttä joko sähköpostitse{' '}
            <span className="contact-details">
              hakemukset@suursavonergiasaatio.fi
            </span>{' '}
            tai puhelimitse{' '}
            <span className="contact-details">0440 365 333</span>
          </p>
        </div>
      </div>
    );
  }
}

export default ApplicationSubmitted;
