import * as React from 'react';
import { toLoginPage, backToHome } from '../../utils/customRouter';
import logo from '../../../src/assets/images/suursavonenergiasaatio-logo.png';
import * as firebase from 'firebase';
import history from '../../routerHistory';
import { NAVIGATION_PATH } from 'paths';

interface TopHeaderState {
  userLoggedIn?: boolean;
  userEmail?: string;
}

class TopHeader extends React.Component<any, TopHeaderState> {
  public unsubscribe: () => void;

  public state: TopHeaderState = {};

  public componentDidMount = () => {
    this.unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ userLoggedIn: true, userEmail: user.email });
      } else {
        this.setState({ userLoggedIn: false, userEmail: undefined });
      }
    });
  };

  public componentWillUnmount = () => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  };

  public logOut = () => {
    this.setState({ userLoggedIn: false, userEmail: undefined });
    // TODO don't push if already on home page
    history.push(NAVIGATION_PATH.HOME);
    firebase.auth().signOut();
  };

  public render() {
    return (
      <div className="row white-background">
        <div className="row">
          {!this.state.userLoggedIn ? (
            <button onClick={toLoginPage} className="btn button-login">
              Kirjaudu
            </button>
          ) : (
            <>
              {this.state.userEmail ? (
                <h5 className="user-email">{this.state.userEmail}</h5>
              ) : (
                <></>
              )}

              <button onClick={this.logOut} className="btn button-login">
                Kirjaudu ulos
              </button>
            </>
          )}
        </div>
        <div className="row">
          <a onClick={backToHome} className="header-link">
            <img src={logo} className="img-responsive header-logo" />
          </a>
        </div>
      </div>
    );
  }
}

export default TopHeader;
