import * as React from "react";

/**
 * Build details
 *
 */
export default class BuildInfo extends React.PureComponent {
  public render() {
    return (
      <div>
        <p>environment: {CONFIG.build.environment}</p>
        <p>version: {CONFIG.build.version}</p>
        <p>build: {CONFIG.build.build}</p>
        <p>sha1: {CONFIG.build.sha1}</p>
        <p>branch: {CONFIG.build.branch}</p>
        <p>tag: {CONFIG.build.tag}</p>
      </div>
    );
  }
}
