import * as React from 'react';
import Heading from 'components/Heading';
import './TrackingComponent.css';
import { db } from '../../firebaseApp';
import { RouteComponentProps } from 'react-router';
import {
  ApplicationForm,
  ApplicationStatus,
  APPLICATION_LIMIT_WITH_QUEUE,
  APPLICATION_LIMIT_WITHOUT_QUEUE
} from '../../../../sses-backend/types';
import { setLoading } from 'components/LoadingSolarPanels';
import FileUploader from 'containers/public/ApplicationForm/FileUploader';
import AttachmentList from 'containers/public/ApplicationForm/AttachmentList';
import Markdown from 'react-remarkable';
import { Button } from 'react-bootstrap';
import service from '../../services/apiService';
import {
  checkApplicationStatus,
  checkApplicationStatusStyle,
  resolveApplicationStatusMessage
} from 'utils/sortingUtils';

interface TrackingComponentState {
  uploadedFiles?: object | null;
  application?: ApplicationForm | null;
  applicationPeriodOpen?: boolean;
  limitWithoutQueueFilled?: boolean;
}

class TrackingComponent extends React.Component<
  RouteComponentProps,
  TrackingComponentState
> {
  public unsubscribe: () => void;

  public state: TrackingComponentState = {
    applicationPeriodOpen: true
  };

  public handleSumbit = () => {
    const formData: FormData = new FormData();
    formData.append('trackingId', this.state.application.trackingId);
    if (this.state.uploadedFiles) {
      Object.keys(this.state.uploadedFiles).map(key =>
        formData.append(key, this.state.uploadedFiles[key])
      );
    }
    setLoading(true);
    service
      .upload(formData)
      .then(response => {
        setLoading(false);
        this.setState({ uploadedFiles: null });
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  public handleFileUpload = (uploadedFiles: FileList | null) => {
    if (uploadedFiles) {
      const files: object = { ...this.state.uploadedFiles };
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles.item(i) != null) {
          const newFile: File = uploadedFiles.item(i)!;
          files[Date.now() + uploadedFiles.item(i).name] = newFile;
        }
      }
      this.setState({ uploadedFiles: files });
    }
  };

  public removeAttachment = (key: string) => {
    const uploadedFiles = { ...this.state.uploadedFiles };
    delete uploadedFiles[key];
    this.setState({ uploadedFiles });
  };

  public handleClick = () => {
    const { params } = this.props.match;
    const redirectUrl = this.generateVismaRedirectUrl(
      this.state.application.invitation.uuid,
      this.state.application.invitation.passphrase
    );
    window.open(redirectUrl, '_blank');
  };

  public generateVismaRedirectUrl = (uuid: string, pass: string) => {
    return 'https://www.onnistuu.fi/fi/invitation/' + uuid + '/' + pass;
  };

  public componentDidMount = async () => {
    // Get uuid and passphrase as params from ReactRouter
    const params = this.props.match.params as any;

    const results = db
      .collection('application_forms')
      .where('trackingId', '==', params.trackingId)
      .where('email', '==', params.email)
      .limit(1)
      .get();
    setLoading(true);
    const responses = await results;
    setLoading(false);
    this.setState({ application: responses.docs[0].data() });
    this.unsubscribe = db
      .collection('application_forms')
      .doc(responses!.docs[0].id)
      .onSnapshot(doc => {
        this.setState({ application: doc.data() });
      });

    service
      .checkCount()
      .then(response => {
        // response.data is the current number of signed applications
        const currentSignedCount = response.data;
        if (response.data >= APPLICATION_LIMIT_WITH_QUEUE) {
          this.setState({ applicationPeriodOpen: false });
        }
        if (response.data >= APPLICATION_LIMIT_WITHOUT_QUEUE) {
          this.setState({ limitWithoutQueueFilled: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  public componentWillUnmount = () => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  };

  public render() {
    const application = { ...this.state.application };
    return (
      <div className="all-content exo-font">
        <Heading text="Hakemuksesi tila" />

        <div className="content">
          <div className="row">
            <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 text-left">
              <h5 className="contact-details">Hakemus:</h5>
              <h5 className="contact-details">Tila:</h5>
            </div>
            <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 text-left">
              <h5 className="text-primary application">
                <strong>{application ? application.trackingId : '--'}</strong>
              </h5>
              <h5
                className={`${
                  application
                    ? checkApplicationStatusStyle(application.status)
                    : ''
                } text-uppercase`}>
                <strong>
                  {application
                    ? checkApplicationStatus(application.status)
                    : '--'}
                </strong>
              </h5>
            </div>
          </div>

          {application &&
          application.status === ApplicationStatus.UNSIGNED &&
          this.state.applicationPeriodOpen ? (
            <>
              <button
                className="btn exo-font AppButton AppButtonColor"
                onClick={this.handleClick}>
                Allekirjoita <i className="material-icons">create</i>
              </button>
              <div className="col text-left">
                {this.state.limitWithoutQueueFilled ? (
                  <>
                    <p className="text-left">
                      <strong>
                        Kiitos hakemuksestanne. Kampanjamme on saavuttanut
                        suuren suosion ja avustusten enimmäismäärä (200
                        hakemusta) on tullut täyteen. Jos hakemuksia
                        peruutetaan, voimme ottaa allekirjoitetun hakemuksen
                        myöhemmin uudelleen käsittelyyn. Hakemus tulee siis
                        allekirjoittaa sähköisesti seuraavien ohjeiden
                        mukaisesti.
                      </strong>
                    </p>
                    <p className="text-left">
                      Ole hyvä ja allekirjoita hakemus painamalla
                      “allekirjoita”-painiketta. Allekirjoittamiseen tarvitset
                      pankkitunnukset tai mobiilivarmenteen. Saat linkin
                      allekirjoituspalveluun myös sähköpostiisi. Hakemus
                      raukeaa, mikäli hakemusta ei allekirjoiteta viiden päivän
                      sisällä. Huomaa, että allekirjoituksen jälkeen voi kestää
                      n. minuutin, ennen kuin hakemuksen tila muuttuu tällä
                      sivulla.
                    </p>
                    <p className="text-left">
                      Hakemuksenne nopeuttamiseksi tehostamiseksi pyydetään
                      lähettämään liitteet ensisijaisesti tämän järjestelmän
                      kautta. Jos lähetät tästä huolimatta hakemuksen liitteet
                      tavallisella kirjepostilla, lähetä ne viikon kuluessa
                      osoitteeseen:
                    </p>
                    <p className="text-left">
                      Suur-Savon Energiasäätiö sr <br /> c/o Ervasti Kalle
                      Savilahdenkatu 5-7 B <br /> 50100 Mikkeli
                    </p>
                    <p className="text-left">
                      Lähetettyjä dokumentteja ei palauteta.
                    </p>
                  </>
                ) : (
                  <p className="application-status-message">
                    <Markdown
                      source={resolveApplicationStatusMessage(
                        application.status
                      )}
                    />
                  </p>
                )}
              </div>
            </>
          ) : application && application.status !== null ? (
            <>
              {!this.state.applicationPeriodOpen &&
              application.status === ApplicationStatus.UNSIGNED ? (
                <h3 className="exo-font text-left">
                  Hakemusten enimmäismäärä on saavutettu ja hakulomake on
                  suljettu.
                </h3>
              ) : (
                ''
              )}
              <p className="application-status-message">
                <Markdown
                  source={resolveApplicationStatusMessage(application.status)}
                />
              </p>
            </>
          ) : (
            <></>
          )}

          <div className="col text-left">
            <p>
              Mikäli haluat muuttaa hakemustasi tai lisätä hakemukseesi tietoja,
              ota meihin yhteyttä sähköpostitse hakemukset@energiasaatio.fi.
            </p>
          </div>
        </div>

        {application.status !== ApplicationStatus.ALL &&
        application.status !== ApplicationStatus.ERROR &&
        application.status !== ApplicationStatus.UNSIGNED ? (
          <>
            <Heading text="Allekirjoitetut dokumentit" />
            <AttachmentList fileUrls={[application.pdfUrl]} />
          </>
        ) : (
          <></>
        )}
        <Heading text="Lisää tiedostoja" />
        {application && application.files ? (
          application.status === ApplicationStatus.UNSIGNED ? (
            <AttachmentList
              files={application.files}
              removeAttachment={this.removeAttachment}
            />
          ) : (
            <AttachmentList files={application.files} />
          )
        ) : (
          <></>
        )}
        <FileUploader
          files={this.state.uploadedFiles}
          handleFileUpload={this.handleFileUpload}
          removeAttachment={this.removeAttachment}
        />

        {this.state.uploadedFiles &&
        Object.keys(this.state.uploadedFiles).length > 0 ? (
          <Button
            className="btn btn-success exo-font AppButton AppBottomButton"
            onClick={this.handleSumbit}>
            Lähetä <i className="material-icons send-icon">send</i>
          </Button>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default TrackingComponent;
